import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/jordan-tpz/Workspace/TROISPOINTZERO/pecheur-docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "v1---écosystème-de-lapplication-mobile-pêcheurcom"
    }}>{`V1 - Écosystème de l'application mobile pêcheur.com`}</h1>
    <p>{`L'application mobile pêcheur.com (version 1) a été réalisée à l'aide du framework `}<a parentName="p" {...{
        "href": "https://reactnative.dev/"
      }}>{`React-Native`}</a>{` v0.59.10.`}</p>
    <p>{`Pour alimenter le contenu de cette dernière, 2 APIs principales sont appelées depuis l'application mobile, dont les endpoints sont les suivants :`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "http://pecheur.tpzdev.fr/api"
        }}>{`http://pecheur.tpzdev.fr/api`}</a>{` (by troispointzero)`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://api.pecheur.com/v1"
        }}>{`https://api.pecheur.com/v1`}</a>{` (by pêcheur.com)`}</li>
    </ol>
    <h2 {...{
      "id": "1-lapi-pecheurtpzdevfr-by-troispointzero"
    }}>{`1. L'API pecheur.tpzdev.fr (by troispointzero)`}</h2>
    <p>{`L'application mobile se voulant orientée sur les « marques pêcheur.com » et sur la mise en avant « d'événements » basés sur ces dernières,
`}<a parentName="p" {...{
        "href": "https://www.troispointzero.fr/"
      }}>{`TroisPointZéro`}</a>{` a développé un back-office dans le cadre de ce projet (s'agissant de fonctionnalités non disponibles depuis l'environnement pêcheur.com lorsque le projet a débuté).`}</p>
    <p>{`Ce back-office permet d'administrer le contenu de l'application mobile.`}</p>
    <p>{`De fait, une API a été mise en place afin de permettre à l'application mobile d'interfacer avec ce back-office :`}</p>
    <table>
  <tr>
    <th>URL</th>
    <th>Description</th>
  </tr>
  <tr>
    <th><a href="http://pecheur.tpzdev.fr">http://pecheur.tpzdev.fr</a></th>
    <th>Interface d'administration - c-à-d le back-office</th>
  </tr>
  <tr>
    <th><a href="http://pecheur.tpzdev.fr/api">http://pecheur.tpzdev.fr/api</a></th>
    <th>API endpoint - lien qui donne aussi accès à sa documentation</th>
  </tr>
    </table>
    <blockquote>
      <p parentName="blockquote">{`A savoir: ce back-office est également utilisé pour envoyer les PUSH Notifications sur les devices des users de façon programmée (via une tâche CRON).`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`A savoir: les marques pêcheur.com stockées dans le back-office peuvent être modifiées/complétées via un fichier csv depuis le serveur du back-office.`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`A savoir: ce back-office n'interface pas avec l'API pêcheur.com !`}</p>
    </blockquote>
    <h2 {...{
      "id": "2-lapi-apipecheurcom-by-pêcheurcom"
    }}>{`2. L'API api.pecheur.com (by pêcheur.com)`}</h2>
    <p>{`Couplée avec l'api `}<a parentName="p" {...{
        "href": "http://pecheur.tpzdev.fr/api"
      }}>{`http://pecheur.tpzdev.fr/api`}</a>{`, cette API permet d'entretenir une communication avec les données issues de l'environnement pêcheur.com dans l'application mobile.`}</p>
    <p>{`Voici ci-après les principales opérations que l'application mobile effectue avec l'environnement pêcheur.com par le biais de cette API :`}</p>
    <blockquote>
      <p parentName="blockquote">{`Pour rappel, l'endpoint de cette API est : api.pecheur.com/v1`}</p>
    </blockquote>
    <table>
  <tr>
    <th>Route</th>
    <th>Méthode HTTP</th>
    <th>Description</th>
  </tr>
  <tr>
    <td>/login</td>
    <td>POST</td>
    <td>identification d'un utilisateur API pour sécuriser l'accès à l'API pêcheur</td>
  </tr>
  <tr>
    <td>/customers/getByCredentials</td>
    <td>POST</td>
    <td>fait office de route /login. Permet la récupération des informations d'un utilisateur pêcheur.com (si il existe)</td>
  </tr>
  <tr>
    <td>/customers/</td>
    <td>POST</td>
    <td>création d'un utilisateur (inscription)</td>
  </tr>
  <tr>
    <td>/customers/requestPassword/[USER_EMAIL]</td>
    <td>GET</td>
    <td>mot de passe oublié</td>
  </tr>
  <tr>
    <td>/customers/[USER_ID]/authToken</td>
    <td>GET</td>
    <td>récupération du token utilisateur pour interroger l'API pêcheur.com</td>
  </tr>
  <tr>
    <td>/customers/[USER_ID]/categories</td>
    <td>GET</td>
    <td>récupération des catégories (univers) d'un utilisateur</td>
  </tr>
  <tr>
    <td>/customers/[USER_ID]/categories</td>
    <td>POST</td>
    <td>mise à jour des catégories (univers) d'un utilisateur</td>
  </tr>
    </table>
    <blockquote>
      <p parentName="blockquote">{`La V1 de l'application mobile pêcheur.com ne permettant essentiellement que de rediriger l'utilisateur sur le site web de pêcheur.com, nous conservons sa connexion grâce à une URL du type : destination_url?authToken=token_user !
Par exemple : `}<a parentName="p" {...{
          "href": "https://www.pecheur.com/vente-peche-carnassiers-destockage-carnassier-9051,0,0,0.html?authToken=TOKEN"
        }}>{`https://www.pecheur.com/vente-peche-carnassiers-destockage-carnassier-9051,0,0,0.html?authToken=TOKEN`}</a></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      